@import 'src/styles';

.apple_button {
  min-height: 55px;
  max-width: $maxButtonWidth;
  width: 100%;
  background-color: $blackColor;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-size: $fontSize20;
  font-family: $buttonFontFamily;
  font-weight: $buttonFontWeight;
  padding: 15px;
  border-radius: 28px;
  box-shadow: 0px 1px 4px 0px rgba(183, 181, 170, 0.30);
  position: relative;

  .icon {
    position: absolute;
    left: 15px;
  }
}