@import "./colors";
@import "./variables";
$primaryColor: #ee8f61;
$secondaryColor: #16786c;

@import "src/styles/colors";

@import "../../manage-subscription/src/manage/styles/theme";
@import "../../manage-subscription/src/terminate/styles/theme";

@import "../../chat-flow/src/styles/theme";

$payModalPrimaryColor: var(--primaryColor);
$payModalSecondaryColor: var(--secondaryColor);

$trans: transparent;

$questionAnswerBorderColor: var(--singleAnswerBorderColor, $trans);

$mainBgColor: #f7f7f7;

$colorError: #f24b57;
$colorBorderField: rgba(184, 185, 195, 0.3);
$inputColor: $gray;
$inputCaretColor: $gray;
$checkboxMarkBackground: var(--primaryColor);
$checkboxMarkCheckedBackground: var(--primaryColor);

$paymentDiscount: #FD5347;

$noteBlockBgColor: $mainBgColor;

$engVersionPrimary: $primaryText;

//error popup
$errorPopupPrimaryColor: var(--errorPopupPrimaryColor, $primaryColor);
$errorPopupBgColor: var(--errorPopupBgColor, $white);
$errorPopupTitleColor: var(--errorPopupTitleColor, $errorPopupPrimaryColor);
$errorPopupSubtitleColor: var(--errorPopupSubtitleColor, $mineShaft);

// questions
$questionTitleFontSize: $fontSize22;
$questionTitleAdaptiveFontSize: $fontSize20;
$questionTitleFontWeight: var(--questionTitleFontWeight, $fwb);
$questionTitleBgColor: $white;
$questionRectangleAnswerPadding: 15px 10px;
$questionActiveTitleBgColor: $white;
$questionTitleColor: $mineShaft;
$questionTitleImageColor: var(--questionTitleImageColor);
$questionSubTitleColor: $mineShaft;
$questionUpTitleColor: $mineShaft;
$questionActiveTitleColor: var(--primaryColor);
$questionActiveTitleBorderColor: var(--primaryColor);
$questionIconCheckColor: var(--primaryColor);

$questionTextFontSize: $fontSize16;
$questionTextAdaptiveFontSize: $fontSize16;

$questionAnswerItemBoxShadow: 0 1px 4px rgba(0, 0, 0, 0.1);
$questionTitleStyles: (
);

$questionButtonBgColor: #142b35;
$quotesIconColor: #EBEBEB;
$questionAnswerSubtitleColor: $mineShaft;
$questionAnswerBorderRadius: var(--questionAnswerBorderRadius, 15px);
$selectionsHintFontStyle: var(--selectionsHintFontStyle, italic);
$selectionsHintColor: var(--selectionsHintColor, inherit);
$selectionsHintOpacity: var(--selectionsHintOpacity, .4);
$questionActiveFontWeight: var(--questionTitleFontWeight, $fwb);

$fontFamilyPhilosopher: Philosopher, sans-serif;

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// links
$linkColor: #2879d8;

$fontFamily: Open Sans, sans-serif;

$transition: all 0.3s ease;

// button
$buttonBackground: var(--primaryColor);
$buttonBorderRadius: 50px;
$disabledButtonBg: $grayLight;
$disabledButtonColor: $gray;
$buttonFontFamily: $fontFamily;
$buttonFontSize: $fontSize22;
$buttonFontWeight: $fwb;

// title
$titleH1Color: var(--primaryColor);
$titleH1FontSize: $fontSize26;
$titleH1FontFamily: $fontFamily;
$titleH1FontWeight: $fwb;

$titleH2Color: var(--titleH2Color);
$titleH2FontSize: $fontSize24;
$titleH2FontFamily: $fontFamily;
$titleH2FontWeight: $fwb;

$titleH3Color: $mineShaft;
$titleH3FontSize: $fontSize22;
$titleH3FontFamily: $fontFamily;
$titleH3FontWeight: $fwb;

$titleH4Color: $mineShaft;
$titleH4FontSize: $fontSize20;
$titleH4FontFamily: $fontFamily;
$titleH4FontWeight: $fwb;

$textColor: $primaryText;
$titlePaymentModalSecondary: var(--injectedPaymentPrimaryColor);
$titlePaymentModal: #2D2D2D;

// preloader
$preloaderColor: var(--primaryColor);
$preloaderBg: rgba(255, 255, 255, 0.5);
$preloaderTextColor: $mineShaft;

// subscription
$plansSectionTitle: $mineShaft;
$plansFeatureTextColor: $primaryText;
$plansPrimaryBg: $white;
$plansInactiveBg: $white;
$plansPrimaryColor: $secondaryColor;
$plansInactiveColor: lighten(desaturate($plansPrimaryColor, 40), 60);
$plansPrimaryBorderColor: $secondaryColor;
$plansDiscountBgColor: var(--plansDiscountBgColor, $plansPrimaryBorderColor);
$plansInactiveBorderColor: $trans;
$plansPrimaryTitleColor: $secondaryColor;
$plansInactiveTitleColor: inherit;
$plansPrimaryTextColor: $titleH3Color;
$plansInactiveTextColor: inherit;
$plansPrimaryPriceColor: $white;
$plansInactivePriceColor: lighten(desaturate($plansPrimaryColor, 40), 20);
$plansAccentTitleFS: $fontSize20;
$plansAccentTitleFW: $fwb;
$plansSubtitleLineThroughFS: $fontSize15;
$plansSubtitleLineThroughFW: $fwstb;
$plansProductSubtitleFW: $fwb;
$plansAfterTrialTextFS: $fontSize12;
$plansActiveAfterTrialTextColor: $plansPrimaryTextColor;
$plansBadgeColor: var(--primaryColor);
$plansBadgeColorLight: var(--primaryColorLight);
$plansDisabledBgColor: #dddddd;
$plansDisabledTextColor: #2d2d2d66;
$paymentModalPersonalDiscountColor: var(--secondaryColor);

$plansContainerBorderRadius: 15px;
$plansAccentBorderRadius: 15px;

$fixedButtonBoxBg: $white;
$fixedButtonDiscountColor: $secondaryColor;
$policyCheckBoxTextColor: $mineShaft;
$reservedPriceColor: $secondaryColor;
$reservedPriceColorLight: var(--secondaryColorLight);
$plansDotsColor: #818181;
$accentTextLineThroughColor: $plansBadgeColor;

$radioPlansInactiveBg: transparent;
$radioPlansInactiveBorderColor: #1e3b48;
$radioPlansActiveBg: #23292b;
$radioPlansActiveBorderColor: $white;
$radioPlansActiveTitleColor: $secondaryColor;
$radioPlansActiveColor: var(--primaryColor);
$radioPlansPriceActiveColor: $secondaryColor;
$radioPlansWeekInactiveBg: var(--primaryColor);
$radioPlansColor: $white;

$plansTicketInactiveTextColor: $white;
$plansTicketInactiveAccentTitleColor: rgba($white, 0.8);
$plansTicketInactiveSubTitleColor: $white;
$plansTicketActiveSubTitleColor: rgba($white, 1);
$plansTicketCheckBoxBorderColor: $white;
$plansDiscountTextColor: $white;

//special offer
$soDiscountBlockBg: #e3eeeb;
$soDiscountImageBg: #69a79b1a;
$soDiscountAccentTextColor: var(--primaryColor, $primaryColor);
$soPrevDiscountTextColor: #565656;
$soDiscountBlockBorder: none;
$soCurrentDiscountTextColor: $mineShaft;
$soDiscountBlockPadding: 0;
$soDiscountBlockBorderRadius: none;
$soCurrentDiscountFontFamily: $fontFamily;

$soReservedPriceBg: #2e897f;

$soInfoAccentTextColor: #2e897f;
$soInfoOfferTextBg: #F2F7F6;

$soModalBg: $white;
$soModalDescriptionText: $mineShaft;
$soModalIconClose: $mineShaft;

// select payment method
$spmTitleColor: #f2e6c4;
$spmSelectButtonNotActiveBg: #1e3b48;
$spmSelectButtonNotActiveBorder: #376478;
$spmSelectButtonNotActiveColor: rgba(255, 255, 255, 0.8);
$spmSelectButtonActiveBg: #ffffff;
$spmSelectButtonActiveBorder: #ffffff;
$spmSelectButtonActiveColor: #142b35;
$spmSelectIconActiveColor: #27baa7;
$spmDownloadsBg: #1e3b48;
$spmOrBg: #142b35;
$spmDownloadsLogoColor: #ffffff;
$spmDownloadsFooterColor: #ffffff;
$spmDownloadsFooterLineColor: #2b5567;
$spmPaymentDescriptionColor: rgba(255, 255, 255, 0.4);
$spmCardButtonBg: linear-gradient(2.59deg, #00eedb -77.77%, #27baa7 144.46%),
  #ee8f61;
$spmCardButtonColor: #ffffff;
$spmCardLabelColor: #ffffff;
$spmFontFamily: $fontFamily;

// full payment modal
$fpmBackgroundColor: #1e3b48;
$fpmFontFamily: $fontFamily;

//terms-menu
$sideBarDarkButtonColor: #f2e6c4;
$sideBarLightButtonColor: #2D2D2D;
$sideBarDarkBgColor: #1e3b48;
$sideBarLightBackDropBg: rgba(47, 47, 47, 0.8);
$sideBarDarkBackDropBg: rgba(21, 43, 53, 0.8);
$sideBarColor: $white;
$sideBarMenuBgColor: var(--sideBarMenuBgColor, $white);

// stars section
$storeStatsBlockBorder: 1px dashed #cbe7e2;
$storeStatsStarColor: var(--primaryColor);
$feedbackStarColor: var(--primaryColor);

// signboard
$warningTextColor: #ee8f61;
$warningBgColor: #fdeee4;

$successTextColor: #2e897f;
$successBgColor: #ecf4f3;

$errorTextColor: #c56554;
$errorBgColor: #faf1f0;

// progressbar
$progressBarBgColor: #ebebeb;
$progressBarActiveColor: var(--primaryColor);
$stepsCounterColor: $mineShaft;
$backArrowButtonColor: $mineShaft;
$progressCurrentStepPointColor: var(--progressCurrentStepPointColor, #cbe7e2);
$progressCounterFontFamily: var(--textFontFamily, $fontFamily);

// magic progress
$magicGoalsIconColor: var(--primaryColor);
$magicTextsColor: var(--magicTextsColor, $mineShaft);
$magicTitleFontFamily: var(--titleFontFamily, $fontFamily);
$magicGoalFontFamily: var(--textFontFamily, $fontFamily);
$magicNotActiveGoalBgColor: var(--magicNotActiveGoalBgColor, transparent);
$magicNotActiveGoalBorder: var(--magicNotActiveGoalBorder, 1px $mineShaft solid);
$magicNotActiveGoalIconColor: var(--magicNotActiveGoalIconColor, $magicNotActiveGoalBgColor);
$magicGoalTextsOpacity: var(--magicGoalTextsOpacity, 1);
$emailInputTextFontWeight: var(--emailInputTextFontWeight, $fwn);
$emailInputTextFontFamily: var(--emailInputTextFontFamily, $fontFamily);


// email
$emailNotificationIconColor: var(--primaryColor);
$emailInputColor: var(--primaryColor);
$emailBottomPartBgColor: $white;
$emailTitleColor: $mineShaft;
$emailNotificationColor: $mineShaft;
$emailInputBgColor: #F7F7F7;
$emailInputTextColor: rgba($mineShaft, .8);

$autocompleteEmailBgColor: #F7F7F7;

$mailCheckboxBorderColor: $white;
$mailCheckboxTextColor: $white;

// feedbacks
$feedbackBackgroundColor: $white;
$feedbackTextsColor: $mineShaft;

$feedbackTitleStyles: (
);
$feedbackItemTitleStyles: (
);
$feedbackItemTextStyles: (
);

// moneyback

$moneyBackTextStyles: (
);

// injected payment method
$injectedPaymentCheckBgColor: #ECF4F3;
$injectedPaymentPrimaryColor: #499b92;
$injectedPaymentMethodActiveColor: #499b92;
$injectedPaymentMethodCheckActiveColor: #ecf4f3;
$injectedPaymentMethodColor: $white;
$injectedPaymentMethodArrowColor: #499b92;
$injectedPaymentMethodDark: none;
$injectedPaymentMethodLight: flex;

// fixed-wrapper
$fixedWrapperBgColor: #23535e;

// trials left
$trialsLeftBadgeColor: $white;
$trialsLeftBadgeBg: var(--primaryColor);
$trialsLeftTextColor: var(--primaryColor);

// payment safe
$paymentSafeDark: none;
$paymentSafeLight: inline-block;
$paymentSafeColor: $mineShaft;

// cookie modal

$cookieModalIcon: #F2E6C4;
$cookieModalTextColor: $white;
$cookieModalBg: #2D5668;
$cookieModalPrimary: #14D5C2;
$cookieModalFootnoteText: rgba(255, 255, 255, 0.70);
$cookieModalAcceptColor: $white;

// switch

$switchBgOff: #152B35;
$switchBgOn: #14D5C2;
$switchMarker: $white;

// users joined

$usersJoinedBorder: 1.2px solid #FDEEE4;
$usersJoinedTextColor: $mineShaft;
$usersJoinedBoldColor: #499B92;
$usersJoinedStarColor: #F9D05D;
$usersJoinedAvaBorderColor: $white;

// payment modal
$paymentModalLineThroughPriceColor: var(--payModalPrimaryColor, inherit);
$paymentModalLineThroughPriceOpacity: 0.7;
$paymentModalInputTextColor: $primaryText;
$payModalDiscountColor: var(--payModalPrimaryColor);

$paymentActionModalBgColor: #2D5668;
$paymentActionModalOverlayBgColor: rgba(21, 43, 53, 0.8);
$paymentActionModalTextColor: rgba(255, 255, 255, 0.85);

// contact us modal
$contactUsModalBg: var(--contactUsModalBg, #111d23);
$contactUsModalTitleColor: var(--contactUsModalTitleColor, #f2e6c4);
$contactUsModalSubTitleColor: var(--contactUsModalSubTitleColor, #e1d7b9);
$contactUsModalFieldColor: var(--contactUsModalFieldColor, #428f9e);
$contactUsModalActiveColor: var(--contactUsModalActiveColor, rgb(20, 213, 194));
$contactUsModalSecondaryBg: var(--contactUsModalSecondaryBg, #111d23);
$contactUsModalTextColor: var(--contactUsModalTextColor, #fff);
$contactUsModalDoneIcon: var(--contactUsModalDoneIcon, #111d23);

// my account
$myAccountBg: var(--myAccountBg, #1E3B48);
$myAccountTitleColor: var(--myAccountTitleColor, #f2e6c4);
$myAccountSubTitleColor: var(--myAccountSubTitleColor, rgba($white, 0.80));
$myAccountTextColor: var(--myAccountTextColor, rgba($white, 0.70));
$myAccountHighlightTextColor: var(--myAccountHighlightTextColor, $white);
$myAccountArrowBtnColor: $myAccountTitleColor;
$myAccountInfoBlockBg: var(--myAccountInfoBlockBg, #152B35);

// face reading

$faceReadingLinkMarginTop: var(--faceReadingLinkMarginTop, 20px);
$faceReadingLinkColor: var(--faceReadingLinkColor, $primaryColor);
$faceReadingButtonColor: var(--faceReadingButtonColor, $primaryColor);
$faceReadingProgressNumberColor: var(--faceReadingProgressNumberColor, $primaryColor);
$faceReadingTitleColor: var(--faceReadingTitleColor, $primaryColor);
$faceReadingTextColor: var(--faceReadingTextColor, $white);
$faceReadingBgColor: var(--faceReadingBgColor, #2D2D2D);
$faceReadingBgFooterColor: $faceReadingBgColor;
$faceReadingAnalyzingTextColor: var(--faceReadingAnalyzingTextColor, var(--primaryText));
$faceReadingAnalyzingAnimationColor: var(--faceReadingAnalyzingAnimationColor, linear-gradient(180deg,
      rgba(247, 247, 247, 0) 0%,
      rgba(247, 247, 247, 0.7) 100%));
$faceReadingUploadTitleColor: var(--faceReadingUploadTitleColor, $mineShaft);
$faceReadingUploadTextColor: var(--faceReadingUploadTextColor, $mineShaft);
$faceReadingUploadCloseIconColor: var(--faceReadingUploadCloseIconColor, #2D2D2D);

// select
$selectInputBgColor: var(--inputBgColor);
$selectInputTextColor: var(--inputTextColor);
$selectInputBorder: var(--inputBorder);

// create account
$createAccountEmailExistPopupTextColor: $textColor;
$createAccountTitleColor: $primaryText;
$createAccountBorderColor: #B8B9C3;
$createAccountActiveBorderColor: $primaryText;
$createAccountAsteriskColor: $primaryColor;
$createAccountDisabledBtnColor: '';
$createAccountDisabledBtnOpacity: '';
$createAccountErrorColor: #C56554;
$createAccountInputIconsColor: rgba(#565656, 0.6);
$createAccountInputPlaceholderColor: rgba($mineShaft, 0.5);
$createAccountDisabledFieldOpacity: 1;
$createAccountTitleStyles: (
);
$createAccountSubtitleStyles: (
);
$createAccountInputStyles: (
);
$createAccountInputPlaceholderStyles: (
);
$createAccountInputNameStyles: (
);
$createAccountErrorTextStyles: (
);

$createAccountSuccessPopupBg: var(--createAccountSuccessPopupBg, $white);
$createAccountSuccessPopupSubtitle: var(--createAccountSuccessPopupSubtitle, $mineShaft);

$progressCurrentTextColor: #2E897F;
$progressPrevLineColor: rgba(#A8D4CD, 0.4);
$progressAccentLineColor: $progressPrevLineColor;
$progressAfterLineHeight: 1.2px;
$progressAccentColor: #86C0B9;
$progressNotAccentColor: #ECF4F3;

$createAccountTitleColor: $primaryText;
$createAccountBorderColor: #B8B9C3;
$createAccountActiveBorderColor: #2E897F;
$createAccountAsteriskColor: $primaryColor;
$createAccountDisabledBtnColor: '';
$createAccountDisabledBtnOpacity: '';
$createAccountDividerColor: '';
$createAccountNonActiveBtnBg: '';
$createAccountNonActiveBtnText: '';
$createAccountDisabledTextColor: '';

$resetPasswordTitleColor: $createAccountTitleColor;
$resetPasswordBorderColor: '';
$resetPasswordPlaceholderColor: '';
$resetPasswordFieldTextColor: '';
$resetPasswordSuccessTextColor: '';
$progressIconColor: #fff;
$resetPasswordBlurStyles: (
  'display': none
);
$resetPasswordTextColor: $primaryText;
$resetPasswordSuccessPopupBg: $white;
$resetPasswordErrorColor: '';
$resetPasswordDisabledOpacity: 0.5;

$resetPasswordFieldStyles: (
);
$resetPasswordPlaceholderStyles: (
);
$resetPasswordErrorStyles: (
);