@import 'core/styles';

// colors
$mainBgColor: #f7f7f7;
$secondaryGray: #c7c7c7;
$oldLace: #fdeee4;
$mystic: #d9eae8;
$lightGreenBg: #e3f1ee;
$light-beige: #fef4ef;
$lightGreen: #e1eeed;
$lightRed: #f9e2d6;
$secondLightBlue: #f2fbfa;
$beige: #f2f2f2;
$grayLight: #e0e0e0;
$genoa: #16786c;
$breakerBay: #69a79b;
$redDamask: #dc7f4b;
$darkRed: #e88c5b;
$red: #eda882;
$mineShaft: #2d2d2d;
$primaryText: #333333;
$darkGrayBlue: #335662;
$lightCocoa: #c0afa1;
$dark-beige: #c7b8ab;
$darkPowder: #fcece3;
$blackColor: #000000;
$trans: transparent;
$darkTeal: #2E897F;
$aquaHaze: #ECF4F3;
$darkOrange: #C56554;
$moonlight: #FAF1F0;

$fontFamily: var(--fontFamily);

$engVersionPrimary: #86C0B9;

// colors for SPIN
$teal: #499B92;
$progressBarActiveColor: $teal;

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// goal component
$firstGoalBg: $lightGreenBg;
$firstLabelBg: #bed7d4;
$firstLabelArcBg: #a6c8c4;
$secondGoalBg: $lightRed;
$secondLabelBg: $red;
$secondLabelArcBg: $darkRed;
$thirdGoalBg: #d8e9ef;
$thirdLabelBg: #7eb6c9;
$thirdLabelArcBg: #3e89a2;
$fourthGoalBg: #fef7db;
$fourthLabelBg: #fde387;
$fourthLabelArcBg: #e8ca61;

// buttons
$buttonBackground: $primaryColor;
$buttonBorderRadius: var(--buttonBorderRadius, $buttonBorderRadius);
$buttonFontFamily: var(--fontFamily);
$disabledButtonBg: $oldLace;
$disabledButtonColor: $dark-beige;
$disabledEmailButtonBg: $light-beige;
$disabledEmailButtonColor: $lightCocoa;
$buttonWrapperBg: $secondLightBlue;

// links
$linkColor: #2879d8;
$preloaderColor: $primaryColor;

// form
$colorLabel: #333333;
$colorFocus: #2879d8;
$colorTextField: #333333;
$colorPlaceholder: #dbdbdb;
$colorBgSuccesMessage: #f2ffe5;

$inputColor: $secondaryGray;
$inputCaretColor: $genoa;

$checkboxMarkBackground: $lightGreen;
$checkboxMarkCheckedBackground: $genoa;

$titleH1Color: $primaryColor;
$titleH2Color: $mineShaft;
$titleH2FontSize: $fontSize22;

//manage

$manageCPSecondaryColor: #6D6D6D;
$manageMainColor: #2d2d2d;
$mamangeSuccessModalTextFS: $fontSize18;
$manageHeaderSubtitleFS: $fontSize16;
$managePrimaryFontFamily: 'Open Sans', sans-serif;
$manageCheckboxBg: #CBE7E2;
$managePlansBadgeColor: var(--secondaryColor);
$manageAccentColor: #16786C;
$manageCheckboxChecked: #499B92;
$manageCheckmark: white;
$manageBackButtonColor: #63ADA4;
$manageWarningColor: #16786C;
$manageTextareaBg: #F6F6F6;
$manageModalCloseIcon: #63ADA4;
$manageTextareaBorder: $primaryColor;
$manageSubtitleColor: #2D2D2D;
$manageOverlayBg: rgba(61, 107, 101, 0.50);
$manageBg: #ECF4F3;
$manageCurrentPlanBg: #499B92;
$manageContentContainer: (
  'background-color': $white,
  'border-radius': 15px,
  'padding': 3px 10px 15px,
);
$manageTitleColor: $mineShaft;
$manageTitleFontSize: $fontSize22;
$manageBackArrowColor: #63ADA4;
$manageBorderColor: rgba(45, 45, 45, 0.10);
$manageFeatureTitleFontSize: $fontSize16;
$manageFeatureTextFontSize: $fontSize14;
$manageFeatureBgColor: $white;
$manageFeatureBorder: none;
$manageFeatureCheckBgColor: #E3F1EE;
$manageFeatureCheckIconColor: #499B92;
$manageFeatureSubtitleColor: #6D6D6D;
$manageCancelLinkColor: #6D6D6D;
$manageCancelSuccessTitleColor: $mineShaft;
$manageCancelSuccessTitleFontSize: $fontSize22;
$manageCancelSuccessPadding: 15px 20px;
$manageCancelSuccessHighlightTextFontWeight: 700;
$manageEmailCancelEmailColor: #27BAA7;
$manageCPTitlePrimaryColor: #fff;
$manageCPPrimaryColor: #2d2d2d;
$manageCanceledStatusColor: #C56554;
$manageButtonFontWeight: 700;
$manageLinkBtnFontSize: $fontSize14;
$manageFeaturesTitleFontSize: $fontSize22;
$manageCurrentPlanLeftTextSize: $fontSize14;
$manageCurrentPlanRightTextSize: $fontSize14;
$manageReasonButtonDisabledColor: rgba(224, 224, 224, 1);
$manageReasonButtonDisabledOpacity: 1;
$manageTitleFontWeight: $fwstb;
$manageTitleMarginBottom: 15px;
$manageCurrentPlanTitleWeight: $fwb;
$manageBenefitsTitleWeight: 700;
$manageReasonPopupTitleSize: $fontSize22;
$manageEmailCancelTitleSize: $fontSize26;

$manageAlternativeButtonBg: $manageFeatureBgColor;
$manageAlternativeButtonColor: $primaryColor;

// plan

$plansPrimaryColor: $primaryColor;
$plansPrimaryBorderColor: $primaryColor;
$plansDiscountBgColor: var(--plansDiscountBgColor, $plansPrimaryBorderColor);
$plansPrimaryTitleColor: $primaryColor;
$plansInactivePriceColor: $primaryColor;
$plansBadgeColor: $secondaryColor;
$fixedButtonDiscountColor: $primaryColor;
$reservedPriceColor: $genoa;
$checkboxMarkCheckedBackground: $primaryColor;
$plansInactiveColor: $lightGreen;

$paymentOverlay: $secondaryGray;

$accentTextLineThroughColor: var(--secondaryColor);

// cookie modal

$cookieModalIcon: #E0E0E0;
$cookieModalTextColor: #2D2D2D;
$cookieModalBg: $white;
$cookieModalPrimary: #499B92;
$cookieModalFootnoteText: rgba(#2D2D2D, 0.40);

// users joined

$usersJoinedBorder: 1px dashed #CBE7E2;
$usersJoinedAvaBorderColor: #499B92;

//special offer

$soDiscountBlockBg: var(--soDiscountBlockBg);
$soDiscountImageBg: var(--soDiscountImageBg);
$soDiscountAccentTextColor: var(--soDiscountAccentTextColor);

$soReservedPriceBg: var(--soReservedPriceBg);

$soInfoAccentTextColor: var(--soInfoAccentTextColor);
$soInfoOfferTextBg: var(--soInfoOfferTextBg);

// trials left
$trialsLeftBadgeBg: var(--secondaryColor);

// create account
$createAccountTitleColor: #0D6056;
$createAccountBorderColor: #CBE7E2;
$createAccountActiveBorderColor: #16786C;
$createAccountAsteriskColor: #C56554;
$createAccountDisabledBtnColor: #E0E0E0;
$createAccountDisabledBtnOpacity: 1;

$progressCurrentTextColor: #2E897F;
$progressPrevLineColor: rgba(#A8D4CD, 0.4);
$progressAccentLineColor: $progressPrevLineColor;
$progressAfterLineHeight: 1.2px;
$progressAccentColor: #86C0B9;
$progressNotAccentColor: #ECF4F3;

$createAccountInputNameStyles: (
  'display': block,
  'color': $mineShaft,
  'font-size': $fontSize14,
  'font-weight': $fwb,
  'margin-bottom': 5px,
);

// reset password
$resetPasswordTitleColor: #0D6056;
$resetPasswordDisabledOpacity: 1;
$createAccountNonActiveBtnBg: #E0E0E0;
$createAccountNonActiveBtnText: $white;
$resetPasswordBorderColor: #16786C;
$resetPasswordFieldTextColor: $mineShaft;
$resetPasswordSuccessTextColor: $mineShaft;

$resetPasswordFieldStyles: (
  'width': 100%,
  'border-radius': 10px,
  'border': 1px solid #CBE7E2,
  'background': $white,
  'padding': 15px 40px 15px 15px,
  'font-size': $fontSize16,
  'font-weight': $fwb,
  'line-height': $fontSize23,
  'color': $resetPasswordFieldTextColor,
  'position': relative,
  'caret-color': $resetPasswordFieldTextColor,
);

$resetPasswordPlaceholderStyles: (
  'color': $mineShaft,
  'font-size': $fontSize14,
  'font-weight': $fwn,
  'opacity': 0.4,
);
