@import 'src/styles';

.popup {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  padding: 25px 0;
  background-color: $createAccountSuccessPopupBg;
  border-radius: 40px 40px 0 0;
  border: 0;
  outline: none;

  .img {
    margin-bottom: 25px;
  }

  .text {
    font-size: $fontSize18;
    line-height: $fontSize26;
    color: $createAccountSuccessPopupSubtitle;
    margin: 15px 0 40px;
    padding: 0 60px;

    b {
      color: $resetPasswordTitleColor;
    }
  }
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.30);
  z-index: 110;
}