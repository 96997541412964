:root {
  --primaryColor: #499B92;
  /* Main */
  --primaryColorLight: #499B9275;
  /* Tint */
  --secondaryColor: #ee8f61;
  /* Accent */
  --secondaryColorLight: #ee8f6175;
  --primaryText: #2d2d2d;
  /* Font */
  --mainBg: #f7f7f7;
  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --secondaryText: white;
  --questionHighlightTitleColor: var(--primaryColor);
  --answerTitleTextAlign: center;
  --secondaryBg: white; /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --injectedPaymentPrimaryColor: var(--primaryColor);
  --titleH2Color: var(--primaryText);



  --soDiscountBlockBg: #ECF4F3;
  --soDiscountImageBg: #86C0B92a;
  --soDiscountAccentTextColor: var(--secondaryColor);

  --soReservedPriceBg: var(--primaryColor);

  --soInfoAccentTextColor: var(--primaryColor);
  --soInfoOfferTextBg: #ECF4F3;

  --payModalPrimaryColor: var(--primaryColor);

  --fontFamily: 'Open Sans', sans-serif;


  --progressTextLight: #86C0B9;
}